module.exports = [
  {
    image: require("assets/img/portfolio/OmicsBioAnalytics_logo_screen.png"),
    title: "Omics BioAnalytics",
    description: "RShiny web app and Alexa Skill for Bioinformatics Analyses.",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.shinyapps.io/omicsBioAnalytics/",
      color: "success"
    }]
  },
  {
    image: require("assets/img/portfolio/omicsCentral.png"),
    title: "Omics Central",
    description: "An omics data analytics platform.",
    buttons: [{
      name: "Website",
      link: "https://omics-central.herokuapp.com/home",
      color: "success"
    },
    {
      name: "Poster",
      link: "https://github.com/singha53/singha53.github.io/blob/source/src/assets/img/portfolio/omicsCentral_poster.pdf",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/diablo.png"),
    title: "DIABLO",
    description: "An integrative, multi-omics, multivariate method for multi-group classification",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.ca/diablo/",
      color: "success"
    },
    {
      name: "Paper",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/30657866",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/cjc.png"),
    title: "Electrical and Proteogeomics biomarkers of heart failure.",
    description: "Reproduce analysis for biomarkers of heart failure hospitalizations paper.",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.shinyapps.io/multiomics_HFhospitalizations/",
      color: "success"
    },
    {
      name: "Paper",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/30935638",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/hangman.png"),
    title: "Hangman",
    description: "Guess countries of the 2018 FIFA world cup.",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.ca/Word-Guess-Game/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/Word-Guess-Game",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/starwarsGame.png"),
    title: "RPG game",
    description: "Star Wars role playing game",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.ca/unit-4-game/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/unit-4-game",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/triviaGame.png"),
    title: "Trivia Game",
    description: "Machine Learning Trivia Challenge",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.ca/TriviaGame/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/TriviaGame",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/searchAPI.png"),
    title: "search API",
    description: "Search for Giphies, OMDB and artists/bands.",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.ca/searchAPI/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/searchAPI",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/MultiplayerRPS.png"),
    title: "Multiplayer RPS",
    description: "Rock, paper, scissors, between two papers.",
    buttons: [{
      name: "Website",
      link: "https://amritsingh.ca/RPS-Multiplayer/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/RPS-Multiplayer",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/EatSmart.png"),
    title: "EatSmart",
    description: "Recipe finder using the Yummly API.",
    buttons: [{
      name: "Website",
      link: "https://nreddyd.github.io/EatSmart/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/nreddyd/EatSmart",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/liri.png"),
    title: "LIRI",
    description: "LIRI is a Language Interpretation and Recognition Interface.",
    buttons: [{
      name: "Github",
      link: "https://github.com/singha53/liri-node-app",
      color: "success"
    }]
  },
  {
    image: require("assets/img/portfolio/bamazon.png"),
    title: "Bamazon",
    description: "Amazon-like command line application using Node.js and MySQL.",
    buttons: [{
      name: "Github",
      link: "https://github.com/singha53/bamazon",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/friendFinder.png"),
    title: "Friend Finder",
    description: "Find a friend with similar responses to you.",
    buttons: [{
      name: "Website",
      link: "https://friend-finder-uft.herokuapp.com/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/FriendFinder",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/burger.png"),
    title: "Burger",
    description: "Logger application with persistent data storage with MySQL.",
    buttons: [{
      name: "Website",
      link: "https://burger-uft.herokuapp.com/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/burger",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/eatsmarter.png"),
    title: "Eat Smarter",
    description: "Menu planner with a nuritional dashboard with D3.",
    buttons: [{
      name: "Website",
      link: "https://eatsmarter.herokuapp.com/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/Eat-Smarter-Frontend",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/scraper.png"),
    title: "Scraper",
    description: "Scraper is a a web app that lets users view and leave comments on the latest news from the New York Times.",
    buttons: [{
      name: "Website",
      link: "https://scraper-uft.herokuapp.com",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/scraper",
      color: "info"
    }]
  },
  {
    image: require("assets/img/portfolio/memory.png"),
    title: "Memory game",
    description: "DBZ Memory clicky-game is a a web app that lets users test their memory by selecting each character only once.",
    buttons: [{
      name: "Website",
      link: "https://clicky-game-uft.herokuapp.com/",
      color: "success"
    },
    {
      name: "Github",
      link: "https://github.com/singha53/clicky-game",
      color: "info"
    }]
  }
]