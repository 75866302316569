module.exports = [{
  title: "Heart Failure",
  papers: [{
    image: require("assets/img/research/cjc.png"),
    buttons: [{
      name: "Website",
      link: "https://amritsingh.shinyapps.io/multiomics_HFhospitalizations/",
      color: "success"
    },
    {
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/30935638",
      color: "info"
    }]
  }]
},
{
  title: "Method Development",
  papers: [{
    image: require("assets/img/research/diablo.png"),
    buttons: [{
      name: "Website",
      link: "http://amritsingh.ca/diablo/",
      color: "success"
    },
    {
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/30657866",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/mixomics.png"),
    buttons: [{
      name: "Website",
      link: "http://mixomics.org/mixdiablo/",
      color: "success"
    },
    {
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/29099853",
      color: "info"
    }]
  }]
},
{
  title: "Asthma",
  papers: [{
    image: require("assets/img/research/ajrccm.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/29087730",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/th17_treg_asthma.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/24991220",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/geneMet_asthma.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/23844124",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/mirnas.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/23170939",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/proteomics.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/22930592",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/asthma_geneExp.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/22316092",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/asthma_eos.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/26217105",
      color: "info"
    }]
  }]
},
{
  title: "Cystic Fibrosis",
  papers: [{
    image: require("assets/img/research/cf.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/31176669",
      color: "info"
    }]
  }]
},
{
  title: "Diabetes",
  papers: [{
    image: require("assets/img/research/diabetes.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/26786322",
      color: "info"
    }]
  }]
},
{
  title: "Allergic Rhinitis",
  papers: [{
    image: require("assets/img/research/allergicRhinitis.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/29046347",
      color: "info"
    }]
  }]
},
{
  title: "Occupational Asthma",
  papers: [{
    image: require("assets/img/research/work_asthma.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/28463537",
      color: "info"
    }]
  }]
},
{
  title: "Diesel exposure and asthma",
  papers: [{
    image: require("assets/img/research/mirna_diesel.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/23584289",
      color: "info"
    }]
  },
  {
    image: require("assets/img/research/mirna_diesel_coexposure.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/27283384",
      color: "info"
    }]
  }]
},
{
  title: "Castleman Disease",
  papers: [{
    image: require("assets/img/research/castleman.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/29675946",
      color: "info"
    }]
  }]
},
{
  title: "Rhinosinusitis",
  papers: [{
    image: require("assets/img/research/rhinosinusitis.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/30775697",
      color: "info"
    }]
  }]
},
{
  title: "Babies",
  papers: [{
    image: require("assets/img/research/natureCom_babies.png"),
    buttons: [{
      name: "Read",
      link: "https://www.ncbi.nlm.nih.gov/pubmed/30862783",
      color: "info"
    }]
  }]
}]